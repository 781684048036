import React from "react";

import { DetailedHTMLProps } from "react";
import { Ref } from "react";
import { SVGAttributes } from "react";

import { forwardRef } from "react";

/**
 * @type WebsitesIconProps
 */
export type WebsitesIconProps = Omit<DetailedHTMLProps<SVGAttributes<SVGSVGElement>, SVGSVGElement>, "children">;

/**
 * @const WebsitesIcon
 */
const WebsitesIcon = (props: WebsitesIconProps, ref: Ref<SVGSVGElement>) => {
    return (
        <svg { ...props } height="61" ref={ ref } viewBox="0 0 97.718 61" width="97.718">
            <path d="M 2 0 C 0.90795212 0 0 0.90795212 0 2 L 0 54 C 0 55.092048 0.90795249 56 2 56 L 43.068359 56 L 43.068359 58.820312 L 33.994141 58.820312 L 33.994141 60.621094 L 55.3125 60.621094 L 55.3125 58.820312 L 44.869141 58.820312 L 44.869141 56 L 78.103516 56 L 78.103516 59.294922 C 78.103516 60.236851 78.866663 61 79.808594 61 L 96.011719 61 C 96.95365 61 97.716797 60.236851 97.716797 59.294922 L 97.716797 26.888672 C 97.716797 25.946751 96.95365 25.183594 96.011719 25.183594 L 87 25.183594 L 87 2 C 87 0.90795249 86.092048 0 85 0 L 2 0 z M 2 1.8007812 L 85 1.8007812 C 85.126752 1.8007812 85.199219 1.8732472 85.199219 2 L 85.199219 25.183594 L 79.808594 25.183594 C 79.573111 25.183594 79.35053 25.232055 79.146484 25.318359 C 78.738393 25.490968 78.41089 25.818474 78.238281 26.226562 C 78.238056 26.227088 78.238506 26.22799 78.238281 26.228516 C 78.195477 26.330024 78.161264 26.434518 78.138672 26.544922 C 78.138414 26.546147 78.138927 26.547602 78.138672 26.548828 C 78.116448 26.658691 78.103516 26.772256 78.103516 26.888672 L 78.103516 54.199219 L 2 54.199219 C 1.8732475 54.199219 1.8007813 54.126752 1.8007812 54 L 1.8007812 2 C 1.8007812 1.8732479 1.8732479 1.8007813 2 1.8007812 z M 79.904297 26.984375 L 87 26.984375 L 95.917969 26.984375 L 95.917969 59.199219 L 79.904297 59.199219 L 79.904297 56 L 79.904297 26.984375 z M 85.353516 54.556641 A 0.9003905 0.9003905 0 1 0 85.353516 56.357422 L 91.322266 56.357422 A 0.9003905 0.9003905 0 1 0 91.322266 54.556641 L 85.353516 54.556641 z" fill="currentColor" />
        </svg>
    );
};

export default forwardRef(WebsitesIcon);
