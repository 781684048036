import React from "react";

import { DetailedHTMLProps } from "react";
import { HTMLAttributes } from "react";
import { Link } from "gatsby";
import { Ref } from "react";

import clsx from "clsx";

import { graphql } from "gatsby";
import { useStaticQuery } from "gatsby";

import { forwardRef } from "react";
import { useTranslation } from "react-i18next";

import ArrowRightIcon from "@Js/Component/Icon/ArrowRightIcon";
import EcommerceIcon from "@Js/Component/Icon/EcommerceIcon";
import Heading from "@Js/Component/Heading";
import WebApplicationsIcon from "@Js/Component/Icon/WebApplicationsIcon";
import WebsitesIcon from "@Js/Component/Icon/WebsitesIcon";

import * as classNames from "@Css/Component/Block/HowWeDoWhatWeDoBlock.module.scss";

/**
 * @type WordpressData
 */
type WordpressData = {
    wordpress: {
        otherSettings: {
            services: {
                eCommerceDescription: string;
                eCommercePage: {
                    uri: string;
                };
                webApplicationsDescription: string;
                webApplicationsPage: {
                    uri: string;
                };
                websitesDescription: string;
                websitesPage: {
                    uri: string;
                };
            };
        };
    };
};

/**
 * @type HowWeDoWhatWeDoBlockProps
 */
export type HowWeDoWhatWeDoBlockProps = Omit<DetailedHTMLProps<HTMLAttributes<HTMLElement>, HTMLElement>, "children">;

/**
 * @const HowWeDoWhatWeDoBlock
 */
const HowWeDoWhatWeDoBlock = (props: HowWeDoWhatWeDoBlockProps, ref: Ref<HTMLElement>) => {
    const {
        className,
        ...restProps
    } = props;

    const { t } = useTranslation();

    const data: WordpressData = useStaticQuery(graphql`
        query {
            wordpress {
                otherSettings {
                    services {
                        eCommerceDescription
                        eCommercePage {
                            ... on WordpressPage {
                                uri
                            }
                        }
                        webApplicationsDescription
                        webApplicationsPage {
                            ... on WordpressPage {
                                uri
                            }
                        }
                        websitesDescription
                        websitesPage {
                            ... on WordpressPage {
                                uri
                            }
                        }
                    }
                }
            }
        }
    `);

    return (
        <section { ...restProps } className={ clsx(classNames.howWeDoWhatWeDoBlock, className) } ref={ ref }>
            <div className={ classNames.container }>
                <Heading>{ t("How We Do, What We Do") }</Heading>
                <div className={ classNames.services }>
                    <Link
                        className={ classNames.service }
                        to={ data.wordpress.otherSettings.services.webApplicationsPage.uri }
                    >
                        <section>
                            <WebApplicationsIcon className={ classNames.icon } />
                            <Heading className={ classNames.heading }>{ t("Web Applications") }</Heading>
                            <div className={ classNames.content }>
                                { data.wordpress.otherSettings.services.webApplicationsDescription }
                            </div>
                            <div className={ classNames.arrowIconWrapper }>
                                <ArrowRightIcon className={ classNames.arrowIcon } />
                            </div>
                        </section>
                    </Link>
                    <Link
                        className={ classNames.service }
                        to={ data.wordpress.otherSettings.services.eCommercePage.uri }
                    >
                        <section>
                            <EcommerceIcon className={ classNames.icon } />
                            <Heading className={ classNames.heading }>{ t("E-commerce") }</Heading>
                            <div className={ classNames.content }>
                                { data.wordpress.otherSettings.services.eCommerceDescription }
                            </div>
                            <div className={ classNames.arrowIconWrapper }>
                                <ArrowRightIcon className={ classNames.arrowIcon } />
                            </div>
                        </section>
                    </Link>
                    <Link
                        className={ classNames.service }
                        to={ data.wordpress.otherSettings.services.websitesPage.uri }
                    >
                        <section>
                            <WebsitesIcon className={ classNames.icon } />
                            <Heading className={ classNames.heading }>{ t("Websites") }</Heading>
                            <div className={ classNames.content }>
                                { data.wordpress.otherSettings.services.websitesDescription }
                            </div>
                            <div className={ classNames.arrowIconWrapper }>
                                <ArrowRightIcon className={ classNames.arrowIcon } />
                            </div>
                        </section>
                    </Link>
                </div>
            </div>
        </section>
    );
};

export default forwardRef(HowWeDoWhatWeDoBlock);
