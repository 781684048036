import React from "react";

import { DetailedHTMLProps } from "react";
import { Ref } from "react";
import { SVGAttributes } from "react";

import { forwardRef } from "react";

/**
 * @type WebApplicationsIconProps
 */
export type WebApplicationsIconProps = Omit<DetailedHTMLProps<SVGAttributes<SVGSVGElement>, SVGSVGElement>, "children">;

/**
 * @const WebApplicationsIcon
 */
const WebApplicationsIcon = (props: WebApplicationsIconProps, ref: Ref<SVGSVGElement>) => {
    return (
        <svg { ...props } height="61" ref={ ref } viewBox="0 0 98 61" width="98">
            <path d="M 8 0 C 6.9066217 0.0003881798 6.0003882 0.90662171 6 2 L 6 53 L 1 53 C 0.46156752 53 0 53.461568 0 54 L 0 60 C 0 60.538432 0.46156752 61 1 61 L 86 61 C 86.538432 61 87 60.538432 87 60 L 87 54 C 87 53.461568 86.538432 53 86 53 L 81 53 L 81 46 L 97 46 C 97.538432 46 98 45.538432 98 45 L 98 25 C 98 24.461568 97.538432 24 97 24 L 81 24 L 81 2 C 80.999612 0.90662198 80.093378 0.0003885773 79 0 L 8 0 z M 8 1.8007812 L 79 1.8007812 C 79.121038 1.8008243 79.199176 1.878962 79.199219 2 L 79.199219 12.035156 L 79.111328 11.865234 L 37.101562 33.623047 L 31.140625 23.601562 L 7.8007812 36.054688 L 7.8007812 2 C 7.8008242 1.8789619 7.8789619 1.8008245 8 1.8007812 z M 79.199219 13.845703 L 79.199219 24 L 63 24 C 62.730784 24 62.481524 24.116132 62.298828 24.298828 C 62.116132 24.481524 62 24.730784 62 25 L 62 45 C 62 45.269216 62.116132 45.518476 62.298828 45.701172 C 62.481524 45.883868 62.730784 46 63 46 L 79.199219 46 L 79.199219 53 L 7.8007812 53 L 7.8007812 38.097656 L 30.472656 25.998047 L 36.421875 36.001953 L 79.199219 13.845703 z M 63.800781 25.800781 L 81 25.800781 L 96.199219 25.800781 L 96.199219 44.199219 L 81 44.199219 L 63.800781 44.199219 L 63.800781 25.800781 z M 70.5 31.599609 L 70.5 33.400391 L 90.5 33.400391 L 90.5 31.599609 L 70.5 31.599609 z M 70.5 36.599609 L 70.5 38.400391 L 90.5 38.400391 L 90.5 36.599609 L 70.5 36.599609 z M 1.8007812 54.800781 L 85.199219 54.800781 L 85.199219 59.199219 L 1.8007812 59.199219 L 1.8007812 54.800781 z" fill="currentColor" />
        </svg>
    );
};

export default forwardRef(WebApplicationsIcon);
